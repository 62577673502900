


























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, namespace, State } from 'vuex-class';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Notification from '@/models/graphql/Notification';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import { RawLocation } from 'vue-router';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  format,
  fromUnixTime,
} from 'date-fns';
import ToastActionParams from '@/utils/types/ToastActionParams';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import Event from '@/utils/types/Event';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import Meeting from '@/models/graphql/Meeting';
import Exhibitor from '@/models/graphql/Exhibitor';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';

enum NotificationActionType {
  MEETING_REQUEST_ASSIGNED_MEETING = 'MEETING_REQUEST_ASSIGNED_MEETING',
  MEETING_REQUEST_MEETING_INVITE = 'MEETING_REQUEST_MEETING_INVITE',
  MEETING_REQUEST_RECEIVED = 'MEETING_REQUEST_RECEIVED'
}

const toastStore = namespace('ToastStore');
const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    AvatarSoloWidget,
  },
})
export default class NotificationListMeetingRequestItemComponent extends Vue {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  private readonly community!: Community;

  @Getter
  private readonly authUser!: CommunityUser;

  @State
  private selectedTzName!: string;

  @State
  private dateLocale!: Locale;

  @Prop({ required: true })
  private notification!: Notification;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @agendaStore.Action
  private updateMeetingParticipantState!: (payload: {
    meetingParticipantUid: string;
    state: string;
  }) => Promise<void>;

  @agendaStore.Mutation
  private setEventViewed!: (e: Event) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  private NotificationActionType = NotificationActionType;

  private get isItForYou(): boolean {
    return !!(this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_REQUEST
      && this.notification.notifiedUsers.findIndex((n) => n.uid === this.authUser.uid) > -1);
  }

  private get image(): string | null {
    if (this.notification && this.notification.initiator) {
      if (this.notification.action === NotificationActionType.MEETING_REQUEST_MEETING_INVITE
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.EXHIBITOR) {
        const hydrated = Exhibitor.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.mainPicture;
        }
      } else if (this.notification.action === NotificationActionType.MEETING_REQUEST_RECEIVED
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.mainPicture;
        }
      }
    }
    return null;
  }

  private get userName(): string {
    if (this.notification.action === NotificationActionType.MEETING_REQUEST_RECEIVED
      && this.notification && this.notification.initiator) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return hydrated.fullName;
        }
      }
    }
    return '';
  }

  private get companyName(): string {
    if (this.notification && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
        const hydrated = MeetingRequest.hydrate(this.notification.triggered);
        if (hydrated && hydrated.exhibitor && hydrated.exhibitor.name) {
          return hydrated.exhibitor.name;
        }
      }
    }
    return '';
  }

  private get meetingName(): string {
    if (this.notification && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
        const hydrated = MeetingRequest.hydrate(this.notification.triggered);
        if (hydrated && hydrated.meetingRequestMeeting && hydrated.meetingRequestMeeting.subject) {
          return hydrated.meetingRequestMeeting.subject;
        }
      }
    }
    return '';
  }

  private get meetingDate(): string {
    if (this.notification && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
        const hydrated = MeetingRequest.hydrate(this.notification.triggered);
        if (hydrated
          && hydrated.meetingRequestMeeting
          && hydrated.meetingRequestMeeting.startTime) {
          return format(DateTimeHelper.utcToZonedTimeDate(
            `${hydrated.meetingRequestMeeting.startTime}Z`,
            this.selectedTzName,
          ),
          'PPPp',
          { locale: this.dateLocale });
        }
      }
    }
    return '';
  }

  private get userNavigateTo(): RawLocation {
    if (this.notification && this.notification.action === NotificationActionType.MEETING_REQUEST_RECEIVED) {
      if (this.notification.initiator
        // eslint-disable-next-line no-underscore-dangle
        && this.notification.initiator.__typename === EntityType.USER) {
        const hydrated = CommunityUser.hydrate(this.notification.initiator);
        if (hydrated) {
          return {
            name: 'member-detail',
            params: { memberId: hydrated.uid },
          };
        }
        return '';
      }
      return '';
    }
    return '';
  }

  private get companyNavigateTo(): RawLocation {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
      const hydrated = MeetingRequest.hydrate(this.notification.triggered);
      if (hydrated && hydrated.exhibitor) {
        return {
          name: 'company-detail',
          params: { companyId: hydrated.exhibitor.uid },
        };
      }
      return '';
    }
    return '';
  }

  private get content(): string {
    if (this.notification
      && this.notification.action === NotificationActionType.MEETING_REQUEST_RECEIVED) {
      return `${this.$t('my-notification-list-component.meeting-request.sent')}`;
    }

    if (this.notification
      && this.notification.action === NotificationActionType.MEETING_REQUEST_MEETING_INVITE) {
      return `${this.$t('my-notification-list-component.meeting-request.meet')}`;
    }
    return '';
  }

  private get dateReceived(): string {
    const today = DateTimeHelper.getCurrentDateTime();
    const createdTimeDate = fromUnixTime(this.notification.createdTimestamp as number);
    if (differenceInSeconds(today, createdTimeDate) < 60) {
      return `${this.$t('my-notification-list-component.time-ago.now')}`;
    }

    const diffInMinutes = differenceInMinutes(today, createdTimeDate);
    if (diffInMinutes < 60) {
      return `${this.$t('my-notification-list-component.time-ago.minutes', {
        number: diffInMinutes,
      })}`;
    }

    const diffInDays = differenceInDays(today, createdTimeDate);
    if (diffInDays === 1) {
      return ` ${this.$t('my-notification-list-component.time-ago.yesterday')}`;
    }
    if (diffInDays < 1) {
      return `${this.$t('my-notification-list-component.time-ago.hours', {
        number: differenceInHours(today, createdTimeDate),
      })}`;
    }
    if (diffInDays < 7) {
      return `${this.$t('my-notification-list-component.time-ago.days', { number: diffInDays })}`;
    }

    if (differenceInYears(today, createdTimeDate) === 0) {
      return format(createdTimeDate, `${this.$t('app.date.monthDayShort')}`, {
        locale: this.dateLocale,
      });
    }
    return format(createdTimeDate, `${this.$t('app.date.defaultDateFormat')}`, {
      locale: this.dateLocale,
    });
  }

  private get isAccepted(): boolean {
    if (this.notification
      && this.notification.action === NotificationActionType.MEETING_REQUEST_MEETING_INVITE
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
      const meetingRequest = this.notification.triggered as MeetingRequest;
      return !!(meetingRequest
        && meetingRequest.meetingRequestMeeting
        && meetingRequest.meetingRequestMeeting.participants
        && meetingRequest.meetingRequestMeeting.participants.length > 0
        && meetingRequest.meetingRequestMeeting.participants.findIndex((p) => p.state === MeetingParticipantState.ACCEPTED
          && p.user && p.user.uid === this.authUser.uid) > -1);
    }

    return false;
  }

  private get isDeclined(): boolean {
    if (this.notification
      && this.notification.action === NotificationActionType.MEETING_REQUEST_MEETING_INVITE
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
      const meetingRequest = this.notification.triggered as MeetingRequest;
      return !!(meetingRequest
        && meetingRequest.meetingRequestMeeting
        && meetingRequest.meetingRequestMeeting.participants
        && meetingRequest.meetingRequestMeeting.participants.length > 0
        && meetingRequest.meetingRequestMeeting.participants.findIndex((p) => p.state === MeetingParticipantState.DECLINED
          && p.user && p.user.uid === this.authUser.uid) > -1);
    }
    return false;
  }

  private get isMeetingChatFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE)
      && this.featureByKey(FeatureKeys.COMMUNITY_CHAT_FEATURE).enabled
      && (this.featureByKey(FeatureKeys.COMMUNITY_MEETING_CHAT)
        && this.featureByKey(FeatureKeys.COMMUNITY_MEETING_CHAT).enabled);
  }

  private closeNotificationModal(): void {
    this.$emit('on-close-notification');
  }

  private onInteractedWith(close = true): void {
    if (close) {
      this.closeNotificationModal();
    }
    if (!this.notification.interactedWith) {
      this.$emit('on-interacted-with', this.notification.uid);
    }
  }

  private onDelete(): void {
    this.$emit('on-delete', this.notification.uid);
  }

  private onAccept(): void {
    this.onInteractedWith(false);
    if (!this.isAccepted
      && this.notification
      && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
        const meetingRequest = this.notification.triggered as MeetingRequest;
        if (meetingRequest
          && meetingRequest.meetingRequestMeeting
          && meetingRequest.meetingRequestMeeting.participants
          && meetingRequest.meetingRequestMeeting.participants.length > 0) {
          const participant = meetingRequest.meetingRequestMeeting.participants
            .find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.ACCEPTED);
            this.$root.$emit('on-root-meeting-accepted', {
              uid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
              meeting: meetingRequest.meetingRequestMeeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.ACCEPTED,
            })
              .catch(() => {
                if (this.notification
                  && this.notification.triggered) {
                  this.$set(participant, 'state', MeetingParticipantState.INVITED);
                }
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.ACCEPT_TO_AGENDA,
              'acceptAppointment',
              -1,
              meetingRequest.meetingRequestMeeting.uid,
              StatLoggerCategories.ACCEPT,
              this.$i18n.locale,
            );
          }
        }
      }
    }
  }

  private onDecline(): void {
    this.onInteractedWith(false);
    if (!this.isDeclined
      && this.notification
      && this.notification.triggered) {
      // eslint-disable-next-line no-underscore-dangle
      if (this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
        const meetingRequest = this.notification.triggered as MeetingRequest;
        if (meetingRequest
          && meetingRequest.meetingRequestMeeting
          && meetingRequest.meetingRequestMeeting.participants
          && meetingRequest.meetingRequestMeeting.participants.length > 0) {
          const participant = meetingRequest.meetingRequestMeeting.participants
            .find((p) => p.user && p.user.uid === this.authUser.uid);
          if (participant) {
            this.$set(participant, 'state', MeetingParticipantState.DECLINED);
            this.$root.$emit('on-root-meeting-declined', {
              uid: participant.uid,
              state: MeetingParticipantState.DECLINED,
              meeting: meetingRequest.meetingRequestMeeting,
            });
            this.updateMeetingParticipantState({
              meetingParticipantUid: participant.uid,
              state: MeetingParticipantState.DECLINED,
            })
              .catch(() => {
                if (this.notification
                  && this.notification.triggered) {
                  this.$set(participant, 'state', MeetingParticipantState.INVITED);
                }
              });
            this.$logger.logMatomoStats(
              this.authUser,
              this.community.code as string,
              EntityType.MEETING,
              StatLoggerActions.REJECT_FROM_AGENDA,
              'rejectAppointment',
              -1,
              meetingRequest.meetingRequestMeeting.uid,
              StatLoggerCategories.REJECT,
              this.$i18n.locale,
            );
          }
        }
      }
    }
  }

  private agendaNavigateTo(): void {
    if (this.notification
      && this.notification.triggered
      // eslint-disable-next-line no-underscore-dangle
      && this.notification.triggered.__typename === EntityType.MEETING_REQUEST) {
      const meetingRequest = this.notification.triggered as MeetingRequest;
      this.onInteractedWith(false);
      this.closeNotificationModal();
      this.setViewMode(ViewMode.DETAIL);
      this.setEventViewed(AgendaStoreHelper
        .convertMeetingToEvent(meetingRequest.meetingRequestMeeting as Meeting, this.selectedTzName));
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
    }
  }
}
