import { ActionContext } from 'vuex';
import VueI18n from 'vue-i18n';
import RootState from '@/store/states/RootState';
import LocaleService from '@/services/LocaleService';
import Organizer from '@/models/graphql/Organizer';
import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import LocaleModel from '@/models/LocaleModel';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import MenuType from '@/utils/enums/MenuType';
import MenuModel from '@/models/MenuModel';
import MenuHelpers from '@/utils/helpers/MenuHelpers';
import UiMenu from '@/models/graphql/UiMenu';
import UiPage from '@/models/graphql/UiPage';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import AUTH_TOKEN from '@/utils/constants/SessionToken';
import CookieService from '@/services/CookieService';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import SignInStep from '@/utils/enums/SignInStep';
import SignInCanProceed from '@/utils/types/SignInCanProceed';
import EmailType from '@/utils/enums/EmailType';
import jwtDecode from 'jwt-decode';
import JwtParams from '@/utils/types/JwtParams';
import MasterDomainRepository from '@/repositories/MasterDomainRepository';
import ClientStorage from '@/utils/ClientStore';
import PortalType from '@/utils/enums/PortalType';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import MasterDomain from '@/models/graphql/MasterDomain';
import { TabType } from '@/utils/types/TabType';
import LocaleMessageObject = VueI18n.LocaleMessageObject;

interface StoreLocalePayload {
  localeService: LocaleService;

  locale: string;

  messages: LocaleMessageObject;
}

export default {
  state: new RootState(),
  mutations: {
    setSavedPositions(state: RootState, payload: {
      key: string;
      pos: { x: number; y: number };
    }): void {
      state.savedPositions.set(payload.key, payload.pos);
    },
    setPageClasses(state: RootState): void {
      state.pageClasses = state.currentPage?.cssClasses || state.currentPage?.code || '';
    },
    setPortalType(state: RootState, portalType: string): void {
      // eslint-disable-next-line no-nested-ternary
      state.portalType = portalType === 'Community'
        ? PortalType.UNIFY
        : portalType === 'CommunityExhibitorPortal'
          ? PortalType.UNIFY_EXHIBITOR_PORTAL
          : portalType;
    },
    setAuthToken(state: RootState, payload: { token: string; communityCode?: string }): void {
      const {
        token,
        communityCode,
      } = payload;
      const storedToken = CookieService.getCookie(AUTH_TOKEN);
      if (storedToken) {
        const decodedToken = jwtDecode(storedToken) as JwtParams;
        if (decodedToken.c !== communityCode) {
          CookieService.deleteCookie(AUTH_TOKEN);
          CookieService.setCookie(AUTH_TOKEN, token);
        }
      } else {
        CookieService.setCookie(AUTH_TOKEN, token);
      }
    },
    updateBannedState(state: RootState, banned: boolean): void {
      if (state.authUser) {
        state.authUser.banned = banned;
      }
    },
    setCurrentPage(state: RootState, page: UiPage | null): void {
      state.currentPage = page ? UiPage.hydrate(page) : null;
    },
    setCommunity(state: RootState, community: Community | null): void {
      FileResourceHelper.communityCode = community?.code || '';
      state.community = community;
      state.communityTzName = community?.timeZone || '';
      state.communityTzAbbreviation = DateTimeHelper
        .getTimeZoneAbbreviation(community?.timeZone || '');
      state.localTzName = DateTimeHelper.currentTimeZone();
      state.localTzAbbreviation = DateTimeHelper
        .getTimeZoneAbbreviation(DateTimeHelper.currentTimeZone());
    },
    setMasterDomain(state: RootState, masterDomains: MasterDomain[]): void {
      const unifyDomain = masterDomains.find((m) => m.defaultModule === 'Community');
      if (unifyDomain) {
        state.unifyDomain = 'http://';
        if (unifyDomain.requireHttps) {
          state.unifyDomain = 'https://';
        }
        state.unifyDomain += unifyDomain.name;
      } else {
        state.unifyDomain = '';
      }
      const exhibitorPortalDomain = masterDomains.find((m) => m.defaultModule === 'CommunityExhibitorPortal');
      if (exhibitorPortalDomain) {
        state.exhibitorPortalDomain = 'http://';
        if (exhibitorPortalDomain.requireHttps) {
          state.exhibitorPortalDomain = 'https://';
        }
        state.exhibitorPortalDomain += exhibitorPortalDomain.name;
      } else {
        state.exhibitorPortalDomain = '';
      }
    },
    initSelectedTimeZone(state: RootState): void {
      const forceEventTimezoneFeature = state.features
        .find((f) => f.feature?.key === FeatureKeys.COMMUNITY_TIMEZONE_FEATURE);
      if ((forceEventTimezoneFeature && forceEventTimezoneFeature.enabled)
        || !ClientStorage.getItem('site-time-zone')) {
        state.selectedTzName = state.communityTzName;
        ClientStorage.setItem('site-time-zone', state.selectedTzName);
      } else {
        state.selectedTzName = ClientStorage.getItem('site-time-zone') as string;
      }
      state.selectedTzAbbreviation = DateTimeHelper.getTimeZoneAbbreviation(state.selectedTzName);
    },
    setSelectedTimeZone(state: RootState, timezone: string): void {
      ClientStorage.setItem('site-time-zone', timezone);
      state.selectedTzName = timezone;
      state.selectedTzAbbreviation = DateTimeHelper.getTimeZoneAbbreviation(timezone);
    },
    setPages(state: RootState, pages: UiPage[]): void {
      state.pages = pages;
    },
    setMenus(state: RootState, menus: UiMenu[]): void {
      state.menus = menus;
    },
    setOnBoardings(state: RootState, onBoardings: UiOnboarding[]): void {
      state.onBoardings = onBoardings;
    },
    setEpRootPage(state: RootState, rootPages: UiPage[]): void {
      if (rootPages.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        state.exhibitorPortalRootPage = rootPages[0];
      }
    },
    setFeatures(state: RootState, features: CommunityFeature[]): void {
      state.features = features;
    },
    setLocale(state: RootState, {
      localeService,
      locale,
    }: StoreLocalePayload): void {
      localeService.setLocale(locale);
      if (state.i18n) {
        state.i18n.locale = locale;
      }
    },
    setDefaultLocale(state: RootState, locale: string): void {
      state.defaultLocale = locale;
    },
    setFallbackLocale(state: RootState, locale: string): void {
      if (state.i18n) {
        state.i18n.fallbackLocale = locale;
      }
      state.fallbackLocale = locale;
    },
    setLocales(state: RootState, locales: LocaleModel[]): void {
      state.locales = locales;
    },
    setMessages({ i18n }: RootState, {
      locale,
      messages,
    }: StoreLocalePayload): void {
      if (i18n) {
        i18n.setLocaleMessage(locale, messages);
      }
    },
    mutateLoadingLocales(state: RootState, loading: boolean): void {
      state.loadingLocales = loading;
    },
    mutateLoadingGuestToken(state: RootState, loading: boolean): void {
      state.loadingGuestToken = loading;
    },
    setDateLocale(state: RootState, locale: Locale): void {
      state.dateLocale = locale;
    },
    setSessionSelectedTab(state: RootState, tab: TabType): void {
      state.sessionSelectedTab = tab;
    },
  },
  actions: {
    loadCommunityCodeFromDomain({ commit }: ActionContext<RootState, RootState>): Promise<{
      community: string;
      token: string | null;
    }> {
      const domain = window.location.hostname;
      const repository = new MasterDomainRepository();
      commit('mutateLoadingGuestToken', true, { root: true });
      return repository.loadCommunityConfig(domain)
        .then((domains) => {
          if (domains && domains.token && domains.community) {
            commit('setPortalType', domains.defaultModule || PortalType.UNIFY);
            commit('setAuthToken', {
              token: domains.token,
              communityCode: domains.community,
            });
            commit('mutateLoadingGuestToken', false);

            return {
              community: domains.community,
              token: domains.token,
            };
          }
          return {
            community: domain.split('.')[0],
            token: null,
          };
        });
    },
    load({
      state,
      commit,
      dispatch,
    }: ActionContext<RootState, RootState>): Promise<void> {
      commit('mutateLoadingLocales', true);
      return dispatch('loadCommunityCodeFromDomain')
        .then((payload) => dispatch('configuration/loadConfigs', payload.community))
        .then(() => dispatch('switchLang', state.defaultLocale));
    },
    switchLang({
      rootState,
      rootGetters,
      commit,
    }: ActionContext<RootState, RootState>, locale: string): Promise<void | null> {
      if (!rootState.i18n) {
        return Promise.resolve(null);
      }

      import('date-fns/locale').then((module: Record<string, object>) => {
        let key = locale.replace('_', '');
        let importedLocale = module[key] as Locale;
        if (!importedLocale) {
          [key] = locale.split('_');
          importedLocale = module[key] as Locale;
        }
        commit('setDateLocale', importedLocale);
      });

      let promise = Promise.resolve();
      const localeService = new LocaleService(rootState, rootGetters);
      // Load messages dynamically for the given locale if it was not already loaded
      if (locale !== rootState.fallbackLocale
        && !Object.keys(rootState.i18n.messages)
          .includes(rootState.fallbackLocale)) {
        promise = localeService.messages(rootState.fallbackLocale)
          .then((messages: {}): void => {
            commit('setMessages', {
              locale: rootState.fallbackLocale,
              messages,
            });
            commit('mutateLoadingLocales', false);
          });
      }
      if (!Object.keys(rootState.i18n.messages)
        .includes(locale)) {
        promise = localeService.messages(locale)
          .then((messages: {}): void => {
            commit('setMessages', {
              locale,
              messages,
            });
            commit('setLocale', {
              localeService,
              locale,
            });
            commit('mutateLoadingLocales', false);
          });
      } else {
        commit('setLocale', {
          localeService,
          locale,
        });
      }
      return promise;
    },
  },
  getters: {
    savedPositions(state: RootState): Map<string, { x: number; y: number }> {
      return state.savedPositions;
    },
    isUnifyExhibitorPortal(state: RootState): boolean {
      return state.portalType === PortalType.UNIFY_EXHIBITOR_PORTAL;
    },
    isUnifyPortal(state: RootState): boolean {
      return state.portalType === PortalType.UNIFY;
    },
    communityTimeZone(state: RootState): string {
      return state.communityTzName || '';
    },
    currentPage(state: RootState): UiPage | null {
      return state.currentPage;
    },
    isLoading(state: RootState, getters: Record<string, object | boolean>): boolean {
      return getters['configuration/isLoading'] as boolean
        || state.loadingLocales
        || state.loadingGuestToken;
    },
    authUser(state: RootState): CommunityUser | null {
      return state.authUser;
    },
    authStep(state: RootState): SignInStep | null {
      return state.authStep;
    },
    authEmail(state: RootState): string | null {
      return state.authEmail;
    },
    signInCanProceed(state: RootState): SignInCanProceed | null {
      return state.signInCanProceed;
    },
    signInEmailState(state: RootState): EmailType | null {
      return state.signInEmailState;
    },
    mainOrganizer(state: RootState): Organizer | null {
      return state.community?.organizer || null;
    },
    community(state: RootState): Community | null {
      return state.community;
    },
    communityCode(state: RootState): string {
      return state.community?.code || '';
    },
    pages(state: RootState): UiPage[] {
      return state.pages;
    },
    menuByType(state: RootState): (menuType: MenuType) => MenuModel[] {
      return (menuType: MenuType): MenuModel[] => MenuHelpers
        .mapMenus(state.menus.find((menu: UiMenu):
        boolean => !menuType || menu.code === menuType) as UiMenu);
    },
    featureByKey(state: RootState): (key: FeatureKeys) => CommunityFeature | undefined {
      return (key: FeatureKeys): CommunityFeature | undefined => state.features
        .find((f: CommunityFeature): boolean => f.feature?.key === key);
    },
    features(state: RootState): CommunityFeature[] | null {
      return state.features;
    },
    isSingleEdition(state: RootState): boolean {
      return !!(state.community
        && (state.community?.masterCommunity?.masterEditions?.length || 0) === 1);
    },
  },
  modules: {},
};
