import { ApolloQueryResult, FetchResult } from '@apollo/client';
import CommunityUser from '@/models/graphql/CommunityUser';
import { buildMutationGql, buildQueryGql } from '@/graphql/_Tools/GqlGeneric';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import CreateInterface from '@/repositories/base/CreateInterface';
import UpdateInterface from '@/repositories/base/UpdateInterface';
import ReadInterface from '@/repositories/base/ReadInterface';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import CountInterface from '@/repositories/base/CountInterface';
import GraphQlRepository from '@/repositories/base/GraphQlRepository';

/* eslint-disable max-len */
export default class CommunityUserRepository extends GraphQlRepository<CommunityUser>
  implements ReadInterface<CommunityUser>, UpdateInterface<CommunityUser>, CreateInterface<CommunityUser>, CountInterface {
  get(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    switch (params.operationName) {
      case 'connectionLookup':
        return buildQueryGql({
          operationName: 'connectionLookup',
          operation: 'communityUser',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: 'communityUserConnectionLookupFragment',
          authUser: params.authUser,
          alias: 'connectionLookup',
        })
          .then((query) => this.query<{
            connectionLookup: CommunityUser[];
          }, {}>(query, params.definition.variables, false))
          .then((response: ApolloQueryResult<{
            connectionLookup: CommunityUser[];
          }>) => response.data.connectionLookup[0])
          .then((response) => CommunityUser.hydrate(response as unknown as object) as unknown as CommunityUser);

      default:
        return buildQueryGql({
          operationName: params.operationName || 'GetCommunityUser',
          operation: 'communityUser',
          gqlDefinition: params.definition as GqlQueryDefinition,
          fragmentName: params.fragmentName || 'communityUserBaseFragment',
          authUser: params.authUser,
        })
          .then((query) => this.query<{
            communityUser: Array<CommunityUser>;
          }, {}>(query, params.definition.variables, false)
            .then((response: ApolloQueryResult<{
              communityUser: Array<CommunityUser>;
            }>) => response.data.communityUser[0]));
    }
  }

  authUser(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildQueryGql({
      operationName: 'GetAuthUser',
      operation: 'communityUser',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'communityUserAuthUserFragment',
    })
      .then((query) => this.query<{
        communityUser: Array<CommunityUser>;
      }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{ communityUser: Array<CommunityUser> }>) => {
          if (response.data.communityUser.length > 0 && response.data.communityUser[0]) {
            const user = response.data.communityUser[0];
            // eslint-disable-next-line no-underscore-dangle
            if (user._needsActivation === false) {
              return user;
            }
            return buildQueryGql({
              operationName: 'GetAuthUserAccountState',
              operation: 'communityUser',
              gqlDefinition: params.definition as GqlQueryDefinition,
              fragmentName: 'communityUserAccountStateFragment',
            })
              .then((query2) => this.query<{
                communityUser: Array<CommunityUser>;
              }, {}>(query2, params.definition.variables, false)
                .then((response2: ApolloQueryResult<{ communityUser: Array<CommunityUser> }>) => {
                  if (response2.data.communityUser.length > 0 && response2.data.communityUser[0]) {
                    return CommunityUser.hydrate({ ...user, ...response2.data.communityUser[0] });
                  }
                  return user;
                }));
          }
          return undefined;
        }));
  }

  count(params: GqlPayloadParams): Promise<number | undefined> {
    return buildQueryGql({
      operationName: params.operationName || 'GetCommunityUserCount',
      operation: 'communityUser',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: 'communityUserCountFragment',
      authUser: params.authUser,
    })
      .then((query) => this.query<{
        communityUser: [{ count: number }];
      }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          communityUser: [{ count: number }];
        }>) => response.data.communityUser[0].count));
  }

  filter(params: GqlPayloadParams): Promise<CommunityUser[]> {
    return buildQueryGql({
      operationName: params.operationName || 'FilterCommunityUsers',
      operation: 'communityUser',
      gqlDefinition: params.definition as GqlQueryDefinition,
      fragmentName: params.fragmentName || 'communityUserBaseFragment',
      authUser: params.authUser,
    })
      .then((query) => this.query<{
        communityUser: Array<CommunityUser>;
      }, {}>(query, params.definition.variables, false)
        .then((response: ApolloQueryResult<{
          communityUser: Array<CommunityUser>;
        }>) => response.data.communityUser));
  }

  create(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CreateCommunityUser',
      operation: 'CommunityUserCreate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBaseFragment',
    })
      .then((mutation) => this.mutate<{
        CommunityUserCreate: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserCreate: CommunityUser;
        }>) => response.data?.CommunityUserCreate));
  }

  update(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'CommunityUserUpdate',
      operation: 'CommunityUserUpdate',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserBaseFragment',
    })
      .then((mutation) => this.mutate<{
        CommunityUserUpdate: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          CommunityUserUpdate: CommunityUser;
        }>) => response.data?.CommunityUserUpdate));
  }

  updatePassword(params: GqlPayloadParams): Promise<boolean> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdatePassword',
      operation: 'changeUserPassword',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserForAccountFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        changeUserPassword: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          changeUserPassword: CommunityUser;
        }>) => (!!response.data?.changeUserPassword?.uid)));
  }

  updatePasswordWithTempToken(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'updatePasswordWithTempToken',
      operation: 'changeUserPasswordWithToken',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserForAccountFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        changeUserPasswordWithToken: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          changeUserPasswordWithToken: CommunityUser;
        }>) => response.data?.changeUserPasswordWithToken));
  }

  updateEmail(params: GqlPayloadParams): Promise<boolean> {
    return buildMutationGql({
      operationName: params.operationName || 'UpdateEmail',
      operation: 'triggerEmailChangeEmailSecure',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserForAccountFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        triggerEmailChangeEmailSecure: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          triggerEmailChangeEmailSecure: CommunityUser;
        }>) => (!!response.data?.triggerEmailChangeEmailSecure?.uid)));
  }

  confirmUserEmail(params: GqlPayloadParams): Promise<CommunityUser | undefined> {
    return buildMutationGql({
      operationName: params.operationName || 'ConfirmUserEmail',
      operation: 'changeUserEmail',
      gqlDefinition: params.definition as GqlMutationDefinition,
      fragmentName: params.fragmentName || 'communityUserForAccountFragment',
      authUser: params.authUser,
    })
      .then((mutation) => this.mutate<{
        changeUserEmail: CommunityUser;
      }, {}>(mutation, params.definition.variables)
        .then((response: FetchResult<{
          changeUserEmail: CommunityUser;
        }>) => (response.data?.changeUserEmail ?? undefined)));
  }
}
