import { render, staticRenderFns } from "./NotificationListMeetingRequestItemComponent.vue?vue&type=template&id=815a2be0&scoped=true&"
import script from "./NotificationListMeetingRequestItemComponent.vue?vue&type=script&lang=ts&"
export * from "./NotificationListMeetingRequestItemComponent.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/notification/NotificationListItemComponent.scss?vue&type=style&index=0&id=815a2be0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "815a2be0",
  null
  
)

export default component.exports