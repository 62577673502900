import { Action, Module, Mutation } from 'vuex-module-decorators';
import LoadableState from '@/store/states/LoadableState';
import LoadableStore from '@/store/LoadableStore';
import { MeetingFilter } from '@/graphql/_Filters/MeetingFilter';
import Meeting from '@/models/graphql/Meeting';
import MeetingRepository from '@/repositories/MeetingRepository';
import View from '@/utils/enums/calendar/View';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getUnixTime,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import CommunityUser from '@/models/graphql/CommunityUser';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyUserRoleRepository from '@/repositories/CompanyUserRoleRepository';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';

/* eslint-disable @typescript-eslint/camelcase,max-len */

interface CalendarState extends LoadableState {
  selectedView: View;
  selectedUsers: string[];
  selectedCompany: string;
  selectedDate: Date;
  selectedMeeting: Meeting;
  filter: MeetingFilter;
  search: string;
  meetings: Meeting[];
  companyReps: CommunityUser[];
}

@Module({ namespaced: true })
export default class FullCalendarStore extends LoadableStore<CalendarState> {
  private selectedView: View = View.MONTHLY;

  private selectedUsers: string[] = [];

  private selectedDate: Date = DateTimeHelper.getCurrentDateTime();

  private selectedCompany: string | null = null;

  private selectedMeeting: Meeting | null = null;

  private search = '';

  private filter: MeetingFilter = {};

  private meetings: Meeting[] = [];

  private companyReps: CommunityUser[] = [];

  private readonly meetingRepository = new MeetingRepository();

  private readonly companyUserRoleRepository = new CompanyUserRoleRepository();

  @Action
  private async loadMeetings(): Promise<void> {
    this.context.commit('constructFilter', this.context.rootState.selectedTzName);
    const meetings = await this.meetingRepository.filter({
      operationName: 'LoadCalendarMeetings',
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_FILTER,
          value: this.filter,
        },
        orderBy: {
          type: GqlEntityOrderingType.MEETING_ORDERING,
          value: ['startTimestamp_asc'],
        },
      }),
      authUser: (this.context.rootState.authUser?.uid as string),
    });
    this.context.commit('setMeetings', meetings);
  }

  @Action
  private async loadCompanyReps(): Promise<void> {
    if (this.selectedCompany) {
      const filter = {
        company: {
          uid: this.selectedCompany,
        },
        state: CompanyUserRoleStatusType.ACCEPTED,
      };
      const meetings = await this.companyUserRoleRepository.filter({
        operationName: 'LoadCalendarCompanyReps',
        definition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.COMPANY_USER_ROLE_FILTER,
            value: filter,
          },
        }),
        fragmentName: 'companyUserRoleForCalendarFragment',
      });
      this.context.commit('setCompanyReps', meetings);
    }
    return Promise.resolve();
  }

  @Mutation
  private setSelectedMeeting(meeting: string | null): void {
    if (meeting) {
      this.selectedMeeting = this.meetings.find((m) => m.uid === meeting) || null;
    } else {
      this.selectedMeeting = null;
    }
  }

  @Mutation
  private setCompanyReps(reps: CompanyUserRole[]): void {
    this.companyReps = reps.map((rep) => CommunityUser.hydrate(rep.user || {}));
  }

  @Mutation
  private setMeetings(meetings: Meeting[]): void {
    this.meetings = meetings;
  }

  @Mutation
  private setSelectedView(view: View): void {
    this.selectedView = view;
  }

  @Mutation
  private setSelectedUsers(users: string[]): void {
    this.selectedUsers = Array.from(new Set(users));
  }

  @Mutation
  private setSearchableString(str: string): void {
    this.search = str;
  }

  @Mutation
  private setSelectedCompany(company: string): void {
    this.selectedCompany = company;
  }

  @Mutation
  private setSelectedDate(date: Date): void {
    this.selectedDate = date;
  }

  @Mutation
  private resetStates(): void {
    this.selectedView = View.MONTHLY;
    this.selectedUsers = [];
    this.selectedDate = DateTimeHelper.zonedToUTCTimeDate(
      DateTimeHelper.getCurrentDateTime(),
      this.context.rootState.selectedTzName,
    );
    this.selectedCompany = null;
    this.selectedMeeting = null;
    this.search = '';
    this.filter = {};
    this.meetings = [];
  }

  @Mutation
  private updateParticipantState(participant: MeetingParticipant): void {
    if (participant.meeting && participant.meeting.uid) {
      const meeting = this.meetings.find((m) => m.uid === participant.meeting?.uid);
      if (meeting) {
        const foundParticipant = (meeting.participants || []).find((p) => p.uid === participant.uid);
        if (foundParticipant) {
          foundParticipant.state = participant.state;
        }
      }
    }
  }

  @Mutation
  private constructFilter(selectedTzName: string): void {
    this.filter = { isCancelled: false };
    const repsUids = this.companyReps.map((u) => u.uid);
    if (this.selectedCompany) {
      Object.assign(this.filter, {
        OR: [
          {
            creatorUser: { uid_in: repsUids },
          },
          {
            participants: { user: { uid_in: repsUids } },
          },
        ],
      });
    }
    let startTimestamp: number | null = null;
    let endTimestamp: number | null = null;
    if ([View.MONTHLY, View.LIST].includes(this.selectedView)) {
      startTimestamp = getUnixTime(startOfMonth(this.selectedDate));
      endTimestamp = getUnixTime(endOfMonth(this.selectedDate));
    } else if (this.selectedView === View.WEEKLY) {
      startTimestamp = getUnixTime(startOfWeek(this.selectedDate));
      endTimestamp = getUnixTime(endOfWeek(this.selectedDate));
    } else if (this.selectedView === View.DAILY) {
      startTimestamp = getUnixTime(startOfDay(this.selectedDate));
      endTimestamp = getUnixTime(endOfDay(this.selectedDate));
    }
    if (startTimestamp && endTimestamp && this.search.length === 0) {
      if (this.selectedView === View.DAILY) {
        Object.assign(this.filter, {
          _inStartDate: {
            dates: [format(this.selectedDate, 'yyyy-MM-dd')],
            timezone: selectedTzName,
          },
        });
      } else {
        Object.assign(this.filter, {
          startTimestamp_gte: startTimestamp,
          startTimestamp_lte: endTimestamp,
        });
      }
    }
    if (this.selectedUsers.length > 0) {
      Object.assign(this.filter, {
        OR: [
          {
            creatorUser: { uid_in: this.selectedUsers },
          },
          {
            participants: { user: { uid_in: this.selectedUsers } },
          },
        ],
      });
    }
    if (this.search.length > 0) {
      this.filter = {
        AND: [
          {
            OR: [
              {
                creatorUser: { uid_in: repsUids },
              },
              {
                participants: { user: { uid_in: repsUids } },
              },
            ],
          },
          {
            OR: [
              {
                creatorUser: { name_contains: this.search },
              },
              {
                participants: { user: { name_contains: this.search } },
              },
              {
                subject_contains: this.search,
              },
              {
                description_contains: this.search,
              },
              {
                location_contains: this.search,
              },
            ],
          },
        ],
      };
    }
  }
}
