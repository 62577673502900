/* eslint-disable max-len, @typescript-eslint/camelcase */
import { Action, Module, Mutation } from 'vuex-module-decorators';
import Notification from '@/models/graphql/Notification';
import NotificationRepository from '@/repositories/notification/NotificationRepository';
import CommunityUserConnection from '@/models/graphql/CommunityUserConnection';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import CompanyUserRole from '@/models/graphql/CompanyUserRole';
import EntityType from '@/utils/enums/EntityType';
import GenericEvent from '@/utils/types/GenericEvent';
import LoadableStore from '@/store/LoadableStore';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import { buildMutationDefinition } from '@/graphql/_Tools/GqlMutationDefinition';
import GqlEntityInputType from '@/utils/enums/gql/GqlEntityInputType';
import CommunityUser from '@/models/graphql/CommunityUser';
import { buildSubscriptionDefinition } from '@/graphql/_Tools/GqlSubscriptionDefinition';
import ConnectionStatusType from '@/utils/enums/ConnectionStatusType';
import { NOTIFICATIONS } from '@/utils/constants/PaginationOffsets';

@Module({ namespaced: true })
export default class NotificationStore extends LoadableStore<Notification> {
  notifications: Notification[] = [];

  unreadNotifications = 0;

  micropollNotifications: Notification[] = [];

  mapComponentEvent: Record<string, (event: SubscriptionEvent, componentId: string) => void> = {};

  registeredComponents: Record<NotificationEventType, Array<string>> | null = {} as Record<NotificationEventType, Array<string>>;

  eventPointer: { unsubscribe: () => void } | null = null;

  private closed = true;

  private page = 0;

  private noMoreDataToLoad = false;

  private notificationRepository: NotificationRepository = new NotificationRepository();

  private genericEventPointers: Record<string, { unsubscribe: () => void }> = {};

  get fetchNotifications(): Notification[] {
    return this.notifications
      .filter((n) => n.triggered && (n.triggered as CommunityUserConnection)?.linkState !== ConnectionStatusType.DISCONNECTED);
  }

  get fetchMicroPollNotifications(): Notification[] {
    return this.micropollNotifications;
  }

  get isClosed(): boolean {
    return this.closed;
  }

  get genericEvents(): Record<string, { unsubscribe: () => void }> {
    return this.genericEventPointers;
  }

  protected get repository(): NotificationRepository {
    return this.notificationRepository as NotificationRepository;
  }

  @Action
  getUnreadNotificationCount(): Promise<number | null> {
    const authUserUid = this.context.rootState.authUser?.uid || null;
    if (!authUserUid) {
      return new Promise((resolve) => resolve(null));
    }
    const oldUnreadNotification = this.unreadNotifications;
    return this.repository.count({
      definition: buildQueryDefinition({
        filter: {
          value: {
            action_not: 'MICROPOLL',
            notifiedUsers: {
              uid: authUserUid,
            },
            initiator: {
              uid_not: authUserUid,
            },
            interactedUsers_none: {
              uid: authUserUid,
            },
            triggered_not: null,
          },
          type: GqlEntityFilterType.NOTIFICATION_FILTER,
        },
      }),
    })
      .then((res) => {
        this.context.commit('setUnreadNotification', res);
        return res;
      })
      .catch(() => {
        this.context.commit('setUnreadNotification', oldUnreadNotification);
        return Promise.resolve(null);
      });
  }

  @Action
  paginatedNotifications(): Promise<number | null> {
    const authUserUid = this.context.rootState.authUser?.uid || null;
    if (!authUserUid) {
      return Promise.resolve(null);
    }
    const oldNotifications = this.notifications;
    return (this.repository as NotificationRepository).filter({
      definition: buildQueryDefinition({
        filter: {
          value: {
            action_not: 'MICROPOLL',
            notifiedUsers: {
              uid: authUserUid,
            },
            initiator: {
              uid_not: authUserUid,
            },
            triggered_not: null,
          },
          type: GqlEntityFilterType.NOTIFICATION_FILTER,
        },
        first: NOTIFICATIONS,
        offset: NOTIFICATIONS * this.page,
        orderBy: {
          value: ['createdTimestamp_desc'],
          type: GqlEntityOrderingType.NOTIFICATION_ORDERING,
        },
      }),
      authUser: authUserUid,
    })
      .then((res: Notification[]) => {
        this.context.commit('setPage', this.page + 1);
        this.context.commit('addElements', res);
        if (res.length < NOTIFICATIONS) {
          this.context.commit('setNoMoreDataToLoad');
        }
        return res.length;
      })
      .catch(() => {
        this.context.commit('setElements', oldNotifications);
        return Promise.resolve(null);
      });
  }

  @Action
  paginatedMicropollNotifications(filter: {
    notifiedUId: string;
    offset?: number;
    startTimestamp_lte: number;
    endTimestamp_gte?: number;
  }): Promise<number | null> {
    const oldNotifications = this.micropollNotifications;
    return (this.repository as NotificationRepository).filter({
      operationName: 'paginateMicroPolls',
      definition: buildQueryDefinition({
        filter: {
          value: {
            schemaCode: this.context.rootState.community?.code as string,
            action: 'MICROPOLL',
            _interactedWith: false,
            notifiedUsers_some: {
              uid: filter.notifiedUId,
            },
            initiator: {
              uid_not: filter.notifiedUId,
            },
            triggered_Survey: {
              _userHasNotAnswered: filter.notifiedUId,
              startTimestamp_lte: filter.startTimestamp_lte,
              ...(filter.endTimestamp_gte ? {
                OR: [
                  {
                    endTimestamp_gte: filter.endTimestamp_gte,
                  }, {
                    endTimestamp: null,
                  },
                ],
              } : {}),
            },
          },
          type: GqlEntityFilterType.NOTIFICATION_FILTER,
        },
        first: NOTIFICATIONS,
        offset: filter.offset === 0 ? filter.offset : NOTIFICATIONS * this.page,
        orderBy: {
          value: ['createdTimestamp_desc'],
          type: GqlEntityOrderingType.NOTIFICATION_ORDERING,
        },
      }),
      authUser: filter.notifiedUId,
    })
      .then((res: Notification[]) => {
        this.context.commit('setMicropollNotifications', res);
        return res.length;
      })
      .catch(() => {
        this.context.commit('setMicropollNotifications', oldNotifications);
        return new Promise((resolve) => resolve(null));
      });
  }

  @Mutation
  setPage(page: number): void {
    this.page = page;
  }

  @Mutation
  registerComponent(payload: {
    type: NotificationEventType;
    componentId: string;
    callback: (event: SubscriptionEvent) => void;
  }): void {
    this.mapComponentEvent[payload.componentId] = payload.callback;
    if (this.registeredComponents
      && !Object.keys(this.registeredComponents)
        .includes(payload.type)) {
      Object.assign(this.registeredComponents, { [payload.type]: [] });
    }

    if (this.registeredComponents && this.registeredComponents[payload.type]) {
      this.registeredComponents[payload.type].push(payload.componentId);
    }
  }

  @Mutation
  unregisterComponent(payload: {
    type: NotificationEventType;
    componentId: string;
  }): void {
    delete this.mapComponentEvent[payload.componentId];
    if (this.registeredComponents && this.registeredComponents[payload.type]) {
      this.registeredComponents[payload.type] = this.registeredComponents[payload.type]
        .filter((item) => item === payload.componentId);
    }
  }

  @Mutation
  emptyRegisteredComponent(): void {
    this.registeredComponents = {} as Record<NotificationEventType, Array<string>>;
    this.mapComponentEvent = {};
  }

  @Mutation
  setNoMoreDataToLoad(): void {
    this.noMoreDataToLoad = true;
  }

  @Mutation
  runCallbacks(event: SubscriptionEvent): void {
    if (this.registeredComponents
      && Object.keys(this.registeredComponents)
        .includes(event.type)
      && this.registeredComponents[event.type as NotificationEventType].length > 0) {
      this.registeredComponents[event.type as NotificationEventType].forEach((id) => {
        const callback = this.mapComponentEvent[id];
        if (callback) {
          callback(event, id);
        }
      });
    }
  }

  @Action
  clearAll(uid: string): void {
    const oldNotifications = this.notifications;
    this.context.commit('setElements', []);
    this.context.commit('setPage', 0);
    this.context.commit('setUnreadNotification', 0);
    (this.repository as NotificationRepository).update({
      operationName: 'clearAllNotificationsForUser',
      definition: buildMutationDefinition([
        {
          fieldName: 'userUid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: uid,
        },
      ]),
    })
      .catch(() => this.context.commit('setElements', oldNotifications));
  }

  @Action
  delete(uid: string): void {
    this.context.commit('removeUnreadNotificationCount', uid);
    this.context.commit('removeNotification', uid);
    (this.repository as NotificationRepository).delete({
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.REQUIRED_ID,
          value: uid,
        },
      ]),
    });
  }

  @Mutation
  removeUnreadNotificationCount(uid: string): void {
    const unreadNotification = this.notifications.filter((item) => item.uid === uid && !item.interactedWith);
    if (unreadNotification.length === 1) {
      this.unreadNotifications -= 1;
    }
  }

  @Mutation
  removeNotification(uid: string): void {
    this.notifications = this.notifications.filter(
      (notification: Notification) => notification.uid !== uid,
    );
  }

  @Action
  update({ attributes }: { uid: string; attributes: Partial<Notification> }): void {
    const oldState = this.notifications;
    this.context.commit('updateElement', attributes);
    (this.repository as NotificationRepository)
      .update({
        definition: buildMutationDefinition([
          {
            fieldName: 'entity',
            type: GqlEntityInputType.NOTIFICATION_INPUT,
            value: attributes,
          },
        ]),
      })
      .catch(() => this.context.commit('setElements', oldState));
  }

  @Action
  setInteractedUser(uid: string): void {
    const authUser = this.context.rootState.authUser as CommunityUser;
    this.context.commit('removeUnreadNotificationCount', uid);
    this.repository.update({
      operationName: 'NotificationAddInteractedUser',
      definition: buildMutationDefinition([
        {
          fieldName: 'uid',
          type: GqlEntityInputType.ID,
          value: uid,
        },
        {
          fieldName: 'interactedUser_CommunityUserUid',
          type: GqlEntityInputType.ID,
          value: authUser.uid,
        },
      ]),
      authUser: authUser.uid,
    })
      .then((response) => this.context.commit('updateElement', response));
  }

  @Mutation
  updateElement(attributes: Partial<Notification>): void {
    const modifiedNotification = this.notifications.find(
      (notification: Notification) => notification.uid === attributes.uid,
    );

    if (modifiedNotification && attributes.triggered) {
      Object.assign(modifiedNotification.triggered, attributes.triggered);
    }

    if (modifiedNotification && attributes.interactedWith) {
      modifiedNotification.interactedWith = attributes.interactedWith;
    }
  }

  @Mutation
  updateConnectionNotificationState(connection: Partial<CommunityUserConnection>): void {
    const modifiedNotification = this.notifications
      .find((notification) => notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && notification.triggered.__typename === EntityType.CONNECTION
        && notification.triggered.uid === connection.uid);
    if (modifiedNotification) {
      Object.assign(modifiedNotification.triggered, connection);
    }
  }

  @Mutation
  updateMeetingNotificationState(payload: { state: string; meetingParticipantUid: string }): void {
    const modifiedNotification = this.notifications
      .find((notification) => notification.triggered
        // eslint-disable-next-line no-underscore-dangle
        && notification.triggered.__typename === EntityType.MEETING_PARTICIPANT
        && notification.triggered.uid === payload.meetingParticipantUid);
    if (modifiedNotification) {
      Object.assign(modifiedNotification.triggered, { state: payload.state });
    }
  }

  @Mutation
  setElements(notifications: Notification[]): void {
    this.notifications = notifications;
  }

  @Mutation
  setMicropollNotifications(notifications: Notification[]): void {
    this.micropollNotifications = notifications;
  }

  @Mutation
  addElements(notifications: Notification[]): void {
    this.notifications = [...this.notifications, ...notifications];
  }

  @Action
  closeNotificationPanel(close?: boolean): void {
    this.context.commit('toggleNotification', close);
  }

  @Mutation
  toggleNotification(close?: boolean): void {
    if (close !== undefined) {
      this.closed = close;
    } else {
      this.closed = !this.closed;
    }
  }

  @Mutation
  setEventPointer(eventPointer: { unsubscribe: () => void }): void {
    this.eventPointer = eventPointer;
  }

  @Mutation
  setUnreadNotification(count: number): void {
    this.unreadNotifications = count;
  }

  @Action
  events(): void {
    if (!this.context.rootState.authUser) {
      return;
    }
    const { companyRoles } = this.context.rootState.authUser;
    const companiesId = companyRoles ? companyRoles.map((cr) => cr.company?.uid)
      : null;

    if (!companiesId) {
      return;
    }

    const callback = (event: SubscriptionEvent | null): void => {
      if (event?.success && event.data) {
        const notification = Notification.hydrate(event.data);
        if (notification.notifiedUsers
          .some((n) => [this.context.rootState.authUser?.uid, ...companiesId]
            .includes(n.uid))) {
          if (event.type === NotificationEventType.COMPANY_INVITE
            && notification
            && notification.triggered
            && this.context.rootState.authUser
            && this.context.rootState.authUser.companyRoles) {
            const companyRole = notification.triggered as CompanyUserRole;
            if (companyRole && companyRole.state) {
              const foundIndex = this.context.rootState.authUser.companyRoles
                .findIndex((c) => c.uid === companyRole.uid);
              if (foundIndex > -1
                && [CompanyUserRoleStatusType.INVITE_CANCELLED, CompanyUserRoleStatusType.CANCELLED]
                  .includes(companyRole.state)) {
                this.context.rootState.authUser.companyRoles.splice(foundIndex, 1);
              }
              if (CompanyUserRoleStatusType.ACCEPTED === companyRole.state) {
                if (companyRole.role) {
                  const items = this.context.rootState.authUser.companyRoles;
                  if (items) {
                    const exist = items?.filter((c) => c.uid === companyRole.uid)
                      .length;
                    if (!exist) {
                      items.push(companyRole);
                    }
                  }
                }
                this.context.commit('CompanyUserRoleStore/removeCompanyUserRole', companyRole, { root: true });
              }
              if (CompanyUserRoleStatusType.INVITED === companyRole.state) {
                this.context.commit('CompanyUserRoleStore/pushCompanyUserRole', companyRole, { root: true });
              }
              if ([CompanyUserRoleStatusType.CANCELLED,
                CompanyUserRoleStatusType.DECLINED,
                CompanyUserRoleStatusType.INVITE_CANCELLED].includes(companyRole.state)) {
                this.context.commit('CompanyUserRoleStore/removeCompanyUserRole', companyRole, { root: true });
              }
            }
          }
          const found = this.notifications.findIndex((n) => n.uid === notification.uid);
          if (found >= 0) {
            this.notifications.splice(found, 1, notification);
          } else {
            this.notifications.unshift(notification);
            if (!notification.interactedWith) {
              this.context.commit('setUnreadNotification', this.unreadNotifications + 1);
            }
          }
          this.context.commit('setElements', this.notifications
            .filter((n) => n.initiator
              && !n.notifiedUsers.map((c) => c.uid)
                .includes(n.initiator.uid)));
        }
        this.context.commit('runCallbacks', event);
      }
    };

    this.repository.eventSubscribe({
      callback: callback.bind(this),
      params: {
        operationName: 'NotificationEventsSubscription',
        definition: buildSubscriptionDefinition([
          {
            fieldName: 'token',
            type: GqlEntityInputType.STRING,
            value: this.repository.authToken,
          },
        ]),
      },
    })
      .then((unsubscribe) => {
        this.context.commit('setEventPointer', unsubscribe);
      });
  }

  @Action
  triggerGenericEvent(params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }): void {
    this.notificationRepository.create({
      definition: buildMutationDefinition([
        {
          fieldName: 'channels',
          type: '[String!]!',
          value: params.channels,
        },
        {
          fieldName: 'extra',
          type: 'String',
          value: params.extra,
        },
        {
          fieldName: 'type',
          type: 'String',
          value: params.type,
        },
        {
          fieldName: 'entityId',
          type: 'String',
          value: params.entityId,
        },
      ]),
    });
  }

  @Mutation
  addGenericEventPointer(payload: {
    eventPointer: { unsubscribe: () => void };
    channel: string;
  }): void {
    this.genericEventPointers[payload.channel] = payload.eventPointer;
  }

  @Mutation
  unsubscribeGenericEventPointer(channel: string): void {
    if (!(channel in this.genericEventPointers)) {
      return;
    }
    if (this.genericEventPointers[channel]) {
      this.genericEventPointers[channel].unsubscribe();
    }
    delete this.genericEventPointers[channel];
    this.genericEventPointers = { ...this.genericEventPointers };
  }

  @Action
  genericEvent(payload: { channel: string; customCallback?: () => void }): void {
    const callback = (event: SubscriptionEvent | GenericEvent | null): void => {
      this.context.commit('runCallbacks', event);
    };

    if (!payload.customCallback && !(payload.channel in this.genericEventPointers)) {
      this.context.commit('addGenericEventPointer', {
        eventPointer: null,
        channel: payload.channel,
      });
      this.repository.genericEventSubscribe({
        callback: (payload.customCallback || callback).bind(this),
        params: {
          operationName: 'GenericNotificationEventsSubscription',
          definition: buildSubscriptionDefinition([
            {
              fieldName: 'token',
              type: GqlEntityInputType.STRING,
              value: this.repository.authToken,
            },
            {
              fieldName: 'channel',
              type: GqlEntityInputType.STRING,
              value: payload.channel as string,
            },
          ]),
        },
      })
        .then((unsubscribe) => {
          this.context.commit('addGenericEventPointer', {
            eventPointer: unsubscribe,
            channel: payload.channel,
          });
        })
        .catch(() => {
          this.context.commit('unsubscribeGenericEventPointer', payload.channel);
        });
    }
  }

  @Mutation
  private clearCache(): void {
    if (this.eventPointer) {
      this.eventPointer.unsubscribe();
    }
    this.eventPointer = null;
    this.notificationRepository = new NotificationRepository();
    this.registeredComponents = {} as Record<NotificationEventType, Array<string>>;
    this.mapComponentEvent = {};
    this.notifications = [];
    this.noMoreDataToLoad = false;
    this.page = 0;
    this.closed = true;
  }
}
